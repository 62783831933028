<template>
	<div class="content">
		<section class="thank-you">
			<div class="container">
			<h1 class="title">Дякуємо!</h1>
			<p>Ми зв'яжемось з Вами найближчим часом</p>
			<div class="col-2">
				<router-link to="/" class="btn">На головну</router-link>
				<router-link to="#contacts" class="btn">Контакти</router-link>
			</div>
		</div>
		</section>
		<section>
			<v-contacts/>
		</section>
	</div>
</template>

<style scoped lang="scss">
	@import "../assets/scss/components/thankyou-page.scss"
</style>

<script>
	import Contacts from '@/components/Contacts.vue'

	export default {
		components: {
			'v-contacts': Contacts
		},
		data(){
			return {
			} 
		},
		methods: {
		}
	}
</script>