<template>
	<div class="content">
		<section>
			<div class="container">
				<h1 class="title">
					Політика конфіденційності
				</h1>
				<p><b>A. Вступ</b><br>
	Конфіденційність користувачів нашого веб-сайту має велике значення для нас, і ми докладаємо всіх зусиль для забезпечення її захисту. Дані правила пояснюють, як ми використовуємо вашу персональну інформацію. Погоджуючись із використанням файлів-cookie при першому відвіданні нашого веб-сайту, відповідно до положень даних Правил, ви надаєте нам дозвіл на використання файлів-cookie при кожному вашому наступному візиті.</p>
				<p>
					<b>Б. Права інтелектуальної власності</b><br>
					Цей документ був створений з використанням шаблона із сайту SEQ Legal (<a href="https://seqlegal.com/" target="_blanl" rel="nofolow">seqlegal.com</a>) та модифікований веб-сайтом Лемберг Флаверс (<a href="https://lembergflowers.com.ua" target="_blanl">https://lembergflowers.com.ua</a>)
				</p>
				<p>
					<b>В. Збір персональних даних</b><br>
					Збору, зберіганню та використанню підлягають наступні типи персональних даних:
					<br>
					1. Інформація про ваш комп’ютер, включаючи вашу IP-адресу, географічне розташування, тип і версію браузера та операційну систему;<br>
					2. Інформація про ваші відвідування та використання цього веб-сайту, включаючи реферальні джерела, протяжність візитів, переглянуті сторінки та шляхи навігації по сайту;<br>
					3. Інформація про адресу вашої електронної пошти та/або номер телефону, які ви вказали в контактних формах на нашому веб-сайті;
					<br>4. Інформація, яка генерується при використанні нашого веб-сайту, включаючи інформацію про час, частоту та умови його використання;
					<br>5. Інформація стосовно будь-яких ваших покупок, використаних сервісів або транзакцій, які ви провели через наш веб-сайт, включаючи ваше ім’я, адресу, номер телефону, адресу електронної поштової скриньки та інформацію про кредитну карту;<br>
					6. Інформація, яка генерується при використанні нашого веб-сайту, включаючи інформацію про час, частоту та умови його використання;<br>
					7.Інформація, що міститься в будь-яких повідомленнях, які ви надсилали нам електронною поштою або через наш веб-сайт, включаючи зміст повідомлення та мета дані;<br>
					8. Будь-яка інша персональна інформація, яку ви надіслали нам.
				</p>
				<p>
					Перед тим, як розкрити для нас персональну інформацію третьої особи, ви маєте отримати згоду цієї особи як на розкриття, так і на обробку цієї інформації у відповідності до даних правил.
				</p>
				<p>
					<b>Г. Використання вашої персональної інформації</b><br>
					Персональна інформація, яку ви передані нам через наш веб-сайт, буде використовуватися задля цілей, зазначених в цих правилах або на відповідних сторінках веб-сайту. Ми можемо використовувати вашу персональну інформацію в наступних цілях:<br>
					- адміністрування нашого веб-сайту та бізнесу;<br>
					- персоналізація нашого веб-сайту для вас;<br>
					- надання вам можливості користуватися сервісами, доступними на нашому веб-сайті;<br>
					- надсилання вам товарів, придбаних через наш-веб-сайт;<br>
					- надання вам послуг, придбаних через наш веб-сайт;<br>
					- надсилання вам повідомлень, рахунків та нагадувань;<br>
					- про сплату, та отримання платежів від вас;<br>
					- надсилання вам немаркетингових комерційних повідомлень;<br>
					- надсилання вам електронною поштою повідомлень, які ви спеціально запросили;<br>
					- надсилання вам електронної розсилки, якщо ви її замовили (ви в будь-який момент можете повідомити нас, що більше не бажаєте отримувати електронні розсилки від нас);<br>
					- надсилання вам маркетингових повідомлень стосовно нашої ділової активності або ділової активності старанно відібраних сторонніх компаній, яка, на нашу думку, може вас зацікавити, шляхом публікацій або, якщо ви конкретно надали на це згоду – шляхом надсилання електронної пошти або за рахунок використання подібних технологій (ви в будь-який момент можете повідомити нас, що більше не бажаєте отримувати маркетингові повідомлення);<br>
					- надання стороннім компаніям статистичної інформації про наших користувачів (проте, ці сторонні компанії не матимуть змоги ідентифікувати жодного окремого користувача з цих даних);
					- обробка запитів та скарг, зроблених вами або на вас, і які стосуються нашого веб-сайту;
					- з метою забезпечення безпеки нашого сайту та попередження шахрайства;<br>
					- з метою перевірки відповідності умовам та правилам, які регламентують використання нашого веб-сайту (включаючи моніторинг приватних повідомлень, надісланих через сервіс приватних повідомлень нашого веб-сайту); <br>
					та в інших цілях.
				</p>
				<p>Якщо ви надали персональну інформацію для публікації на нашому веб-сайті, ми опублікуємо її. В іншому випадку, ми використовуватимемо цю інформацію у відповідності до ліцензії, яку ви нам надали.</p>
				<p>Ваші налаштування конфіденційності можуть використовуватись для обмеження публікації ваших персональних даних на нашому веб-сайті, і можуть регулюватися за допомогою засобів управління конфіденційністю на веб-сайті.</p>
				<p>Без вашої чітко вираженої згоди ми не будемо передавати вашу персональну інформацію жодній сторонній компанії для прямого маркетингового використання цією, або будь-якою іншою сторонньою компанією.</p>
				<p><b>Д. Розкриття персональної інформації</b><br>
				Ми лишаємо за собою право розкрити вашу персональну інформацію для будь-якого з наших працівників, керівників, страхувальників, професійних радників, агентів, постачальників або субпідрядників, в об’ємі та цілях, визначених в цих правилах.</p>
				<p>Ми за собою право розкрити вашу персональну інформацію для будь-якого члена нашої групи компаній (сюди входять наші дочірні компанії, наша кінцева холдингова компанія та всі її дочірні компанії) в об’ємі та цілях, визначених в цих правилах.</p>
				<p>Ми лишаємо за собою право розкрити вашу персональну інформацію:</p>
				<p>- в тих випадках, в яких цього від нас вимагає закон;<br>
				- у зв’язку з будь-яким поточними або майбутніми судовими процесами;<br>
				- з метою встановлення, реалізації або захисту наших законних прав (включаючи надання інформації іншим сторонам задля запобігання шахрайству або зниження кредитних ризиків);<br>
				- покупцеві (або потенційному покупцеві) будь-якого бізнесу або активів, які ми продаємо (або збираємося продати);<br>
				та будь-якій особі, яка, як ми обґрунтовано вважаємо, може подати запит до суду або іншого уповноваженого органу про розкриття цих персональних даних і, на нашу обґрунтовану думку, цей суд або уповноважений орган видасть розпорядження на розкриття цих персональних даних.</p>
				<p>Ми не будемо розкривати вашу персональну інформацію третім особам, за виключенням випадків, зазначених в цих правилах.</p>
				<p>
					<b>Е. Захист вашої персональної інформації	</b><br>
					1. Ми будемо вживати достатні технічні та організаційні заходи для попередження втрати, протиправного використання чи підробки вашої персональної інформації.<br>
					2. Всю надану вами персональну інформацію ми будемо зберігати на наших захищених (як паролем, так і фаєрволами) серверах.<br>
					3. Всі електронні фінансові транзакції, здійснені за допомогою нашого сайту, будуть захищені технологією шифрування даних.<br>
					4. Ви підтверджуєте своє ознайомлення з тим фактом, що передача інформації через Інтернет є по суті є незахищеною, і ми не можемо гарантувати захист даних, надісланих через всесвітню мережу.<br>
				</p>
				<p>
					<b>Є. Зміни та поправки</b><br>
					Ми лишаємо за собою право періодично вносити зміни та поправки в ці правила, та публікувати їх нову редакцію на нашому сайті. Ви повинні періодично перевіряти цю веб-сторінку, щоб пересвідчитись, що розумієте зміст змін, внесених до цих правил. Ми також можемо проінформувати вас про внесення змін до цих правил шляхом надсилання електронної пошти або через систему передачі приватних повідомлень нашого сайту.
				</p>
				<p>
					<b>Ж. Сторонні веб-сайти</b><br>
					Наш веб-сайт містить гіперпосилання на, та деталі про веб-сайти сторонніх компаній та осіб. Ми не маємо інструментів керування, та не несемо відповідальності за політику конфіденційності й практики сторонніх осіб та компаній в цій галузі.
				</p>
				<p>
					<b>З. Оновлення інформації</b><br>
					Будь-ласка, своєчасно повідомляйте нас, якщо ваша персональна інформація, яка знаходиться у нас, потребує оновлення чи виправлень.
				</p>
			</div>
		</section>
	</div>
</template>

<style scoped lang="scss">
	section {
		padding: 100px 0 50px;
	}
</style>